import '@shared/types-v0.1.0'
import type { Item } from '@shared/types-v0.1.0'

export type SetItems = typeof setItems

export const setItems = (data: any) => {
	if (data) {
		const rawItems = findObjectsWithValidId(data)
		return setRitems(rawItems)
	}
	return
}

export type GetRid = typeof getRid

export const getRid = (table: string, id: string) => {
	const rid = `${table}:\`${id}\``
	return R.surreal.r`${rid}`
}

export const merge = (p: {
	object: any
	proxyObject: any
	parentKey?: string
	parentProxyObject?: any
	skipDelete?: boolean
}) => {
	const { map, typeOf } = R.libs.just
	const { object, proxyObject, parentKey, parentProxyObject, skipDelete } = p

	if (proxyObject) {
		// Обновление.
		map(object, (k, v) => {
			if (typeOf(v) === 'object')
				merge({
					object: v,
					proxyObject: proxyObject[k],
					parentKey: k,
					parentProxyObject: proxyObject,
				})
			// Массив просто перезапишем. Возможно, здесь нужно доработать.
			else proxyObject[k] = v
		})

		// Удаление.
		if (!skipDelete)
			map(proxyObject, (k, v) => {
				if ((object[k] === undefined || object[k] === null) && k !== 'roots' && typeOf(v) !== 'function') delete proxyObject[k]
			})
	} else if (parentKey && parentProxyObject) parentProxyObject[parentKey] = object
}

interface IdStructure {
	id: string
	tb: string
}

const findObjectsWithValidId = (data: any): any[] => {
	const result: any[] = []

	if (typeof data !== 'object' || data === null) {
		return result
	}

	if (hasValidIdField(data)) {
		result.push(data)
	}

	if (Array.isArray(data)) {
		for (const item of data) {
			result.push(...findObjectsWithValidId(item))
		}
	} else {
		for (const value of Object.values(data)) {
			result.push(...findObjectsWithValidId(value))
		}
	}

	return result
}

const isValidIdStructure = (obj: any): obj is IdStructure => {
	return typeof obj === 'object' && obj !== null && typeof obj.id === 'string' && typeof obj.tb === 'string'
}

const hasValidIdField = (obj: any): boolean => {
	return typeof obj === 'object' && obj !== null && 'id' in obj && isValidIdStructure(obj.id)
}

const setRitems = (rawItems: ({ id: { id: string; tb: string } } & Record<string, unknown>)[]) => {
	return rawItems.map((rawItem) => {
		const item = getItem(rawItem)
		const proxyItem = R.items[item.id]

		if (proxyItem) merge({ object: item, proxyObject: proxyItem })
		else R.items[item.id] = item

		return R.items[item.id]
	})
}

const getItem = (rawItem: { id: { id: string; tb: string } } & Record<string, unknown>) => {
	const { id, ...item } = rawItem

	item.id = id.id
	item.table = id.tb
	// Обратная совместимость
	item.dbClass = id.tb.replaceAll('_', '-')

	const prototype = Object.create(
		{
			getRef: (dbClass: string) => {
				const globalItem = R.items[id.id]
				if (globalItem?.[dbClass]) {
					if (Array.isArray(globalItem[dbClass])) return globalItem[dbClass].map((i) => R.items[i.id]).filter((i) => !!i)
					return R.items[globalItem[dbClass].id]
				}
				return undefined
			},
			getBackRef: (dbClass: string) => {
				let resultRefItem: Item | undefined
				R.libs.just.map(R.items as any, (_, refItem: any) => {
					if (refItem.dbClass === dbClass && refItem[item.dbClass as string]?.id === id.id) resultRefItem = refItem
				})
				return resultRefItem
			},
			getDbClassState: (statePath: string) => R.libs.just.get(R.dbClasses, `${item.dbClass}.states.${statePath}`),
		},
		{
			id: { enumerable: true, writable: false, configurable: false, value: id.id },
			dbClass: { enumerable: true, writable: false, configurable: false, value: item.dbClass },
		}
	)

	return Object.assign(prototype, R.libs.just.omit(item, ['id', 'dbClass']))
}
