import type { BaseJsProps } from '@shared/node-v1.0.0'
import type { JsNodeDef } from '@shared/node-v1.0.0'
import { getPortDef } from '@shared/port-v1.0.0'

export type Props = BaseJsProps & BaseProps

export type BaseProps = {
	fileName?: string
	blob?: any
}

export default {
	hashTag: '#pre-release',
	module: { dynamic: import('../component') },
	inNode: {
		inputs: [
			getPortDef({ name: 'saveAs', displayName: 'Save as', group: 'Signals', type: 'signal' }),
			getPortDef({
				name: 'fileName',
				displayName: 'File name',
				group: 'Params',
				type: 'string',
				validate: (p: Props) => Boolean(p.fileName),
			}),
			getPortDef({ name: 'blob', displayName: 'Blob', group: 'Data', type: '*', validate: (p: Props) => Boolean(p.blob) }),
		],
	},
	disableCustomProps: true,
} satisfies JsNodeDef
