import type { NoodlNode } from '@shared/node-v1.0.0'
import type Surreal from 'surrealdb'
import type { Props } from '../definition'

export const initSurrealDb = async (p: Props, noodlNode: NoodlNode) => {
	const { namespace = 'default', database = 'data', customSurrealDb, surrealDbHost, surrealDbPort } = p
	const { project, environment = 'd3' } = Noodl.getProjectSettings()

	if (!project || !environment) {
		log.error('SurrealDb init: empty required props', { project, environment })
		return
	}

	const surrealDbModule = await import('surrealdb')
	const { Surreal, r, surql } = surrealDbModule

	const host = customSurrealDb ? surrealDbHost || 'localhost' : `surrealdb.${project}.${environment}.rolder.app`
	const port = customSurrealDb ? surrealDbPort || 8000 : 443

	R.surreal = {
		namespace,
		database,
		r,
		//@ts-ignore
		surql,
		db: await getDb('https', Surreal, namespace, database, host, port),
		liveDb: await getDb('wss', Surreal, namespace, database, host, port),
	}
}

async function getDb(type: 'https' | 'wss', Surreal: any, namespace: string, database: string, host: string, port: number) {
	const st = log.start()

	const db = new Surreal() as Surreal
	const auth = { username: 'root', password: '#vJIfEGV2$quJE2^u$bW!vBf' }

	try {
		await db.connect(`${type}://${host}:${port}`, { namespace, database, auth })
		log.end(`Surrealdb init ${type}`, st)
		return db
	} catch (err) {
		log.error(`Failed to init SurrealDB (${type}):`, err instanceof Error ? err.message : String(err))
		await db.close()
		throw err
	}
}
