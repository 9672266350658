import { jsNode } from '@shared/node-v1.0.0'

import v010 from '@nodes/use-hierarchy-v0.1.0'

export default jsNode(
	'useHierarchy',
	{
		'v0.1.0': v010,
	},
	{ docs: '' }
)
