import { jsNode } from '@shared/node-v1.0.0'

import v100 from '@nodes/save-as-v1.0.0'

export default jsNode(
	'saveAs',
	{
		'v1.0.0': v100,
	},
	{ docs: '', color: 'purple' }
)
