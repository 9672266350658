import type { BaseProps, Props } from '../node/definition'

export default (p: Props) =>
	({
		rootId: R.libs.nanoid(8),
		inited: false,
		scheme: p.scheme,
		data: p.data,
	}) satisfies Store

export type Store = BaseProps & {
	rootId: string
	inited: boolean
}
