import type { Uuid } from '@nodes/app-v2.0.0'
import { setItems } from '@shared/item-v0.1.0'
import type { NoodlNode } from '@shared/node-v1.0.0'
import type { Props } from '../node/definition'
import { fetch } from './fetch'

export const handleSubscribe = async (p: Props, noodlNode: NoodlNode) => {
	// Подписка
	for (const table of p.liveTables || []) {
		if (!p.store.subscribes.has(table)) subscribe(p, noodlNode, table)
	}

	// Отписка
	p.store.subscribes.forEach((liveUuid, table) => {
		if (!p.liveTables?.includes(table)) unSubscribe(p, table, liveUuid)
	})
}

const subscribe = async (p: Props, noodlNode: NoodlNode, table: string) => {
	const { liveDb } = R.surreal

	const liveUuid = await liveDb.live(table, (action, sItem) => {
		log.info('live action', action, sItem)
		if (action === 'CLOSE') return

		if (action === 'UPDATE') {
			setItems([sItem as any])
			return
		}

		fetch(p, noodlNode)
	})

	p.store.subscribes.set(table, liveUuid)

	log.info(`Subscribed to "${table}"`)
}

const unSubscribe = async (p: Props, table: string, liveUuid: Uuid) => {
	R.surreal.liveDb.kill(liveUuid)
	p.store.subscribes.delete(table)
	log.info(`Unsubscribed from "${table}"`)
}

export const unSubscribeAll = async (p: Props) =>
	await Promise.all([...p.store.subscribes].map(([table, liveUuid]) => unSubscribe(p, table, liveUuid)))
