import initState from '@shared/init-state-v0.1.0'
import type { BaseJsProps } from '@shared/node-v1.0.0'
import type { JsNodeDef } from '@shared/node-v1.0.0'
import { getPortDef } from '@shared/port-v1.0.0'
import getStore from './store'
import type { Store } from './store'

export type Props = BaseJsProps & BaseProps & { store: Store }

export type BaseProps = {
	data: any[]
}

export default {
	hashTag: '#expreimental',
	module: { dynamic: import('../component') },
	inNode: {
		inputs: [
			getPortDef({
				name: 'data',
				displayName: 'Data',
				group: 'Data',
				type: '*',
				validate: (p: Props) => Boolean(p.data),
			}),
		],
		outputs: [
			getPortDef({ name: 'rItems', displayName: 'Items', group: 'Data', type: 'array' }),
			getPortDef({ name: 'done', displayName: 'Done', group: 'Signals', type: 'signal' }),
		],
	},
	afterNode: {
		triggerOnInputs: () => ['key', 'data'],
	},
	beforeComponent: {
		initialize: async (p: Props, noodlNode) => {
			await initState('initialized')
			p.store = getStore(p)
		},
	},
	disableCustomProps: true,
} satisfies JsNodeDef
